import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useLazyAsyncData } from '#app';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import { AlertCodes } from '~/store/alert/alert.messages';
import { useAlertStore } from '~/store/alert/alert.store';
import { useUserStore } from '~/store/user/user.store';
import type {
  IInventory,
  IReplaceInventoryItemRequestDTO,
  TGetInventoryRequestDTO,
  TGetInventoryResponseDTO,
  TInventorySendItemResponseDTO,
} from '~/repository/modules/inventory/inventory.types';
import { EGroupSortParams, EInventoryItemStatus } from '~/repository/modules/inventory/inventory.types';
import { usePagination } from '~/composables/usePagination';
import { dropItemTypes } from '~/types/cases/dropItem';

export const useInventoryStore = defineStore('inventory', () => {
  const { $api } = useNuxtApp();
  const alertStore = useAlertStore();
  const userStore = useUserStore();

  const route = useRoute();
  const { user, isUserLoaded } = storeToRefs(userStore);

  // Является ли инвентарь собственным
  const isCurrentUser = computed(() => {
    return Number(route.params.userId) === user.value?.userId;
  });

  // инвентарь пользователя
  const userInventory = ref<IInventory[]>([]);
  // Все предметы или только активные предметы
  const isAllInventory = ref(false);
  // Общая стоимость предметов в инвентаре
  const totalPrice = ref<string>('');
  // Предметы под замену
  const tradeItems = ref<IInventory[]>([]);
  // можно ли вывести предмет
  const isTakeItemAvailable = ref<boolean>(true);
  // список предметов для замены
  const changeItemList = ref<IInventory[]>([]);
  // предмет, который хотят обменять
  const currentTradeItem = ref<IInventory>();
  // предмет, на который хотят обменять
  const currentExchangeTradeItem = ref<IInventory | null>();
  // состояние видимости Попапа замены предмета
  const isExchangePopupVisible = ref<boolean>(false);
  // время доступности попапа замены
  const currentInventoryExchangePopupTime = ref();
  // выбранный инвентарь
  const selectedInventory = computed<IInventory[]>(() => {
    return userInventory.value.filter((el) => el.isSelected);
  });
  // id выбранных предметов
  const selectedInventoryIds = computed(() => selectedInventory.value.map((item) => item.id));
  // количество выбранных предметов
  const quantitySelectedItems = computed(() => selectedInventory.value.length);
  // общая сумма выбранных предметов
  const totalSumSelectedItems = computed(() => selectedInventory.value.reduce((acc, value) => acc + +value.price, 0));

  const ChangeItemListEntity = computed(() => {
    return changeItemList.value.length;
  });

  // Общее количество активных предметов
  const totalActiveItems = ref(0);

  // Инициализация пагинации
  const total = ref(0);
  const pagination = usePagination({
    onLoadMore: async () => {
      await refreshInventory();
    },
    page: 1,
    pageSize: 45,
    total,
  });

  // Управление состоянием выбранности предмета
  const toggleSelectItemState = (item: IInventory) => {
    if (item.type && item.type === dropItemTypes['3']) {
      item.isSelected = !item.isSelected;
    }
  };

  // выбрать все
  const selectedAllItem = () => {
    userInventory.value.forEach((el) => {
      el.isSelected = true;
    });
  };
  // сбросить все
  const removeAllFromSelected = () => {
    userInventory.value.forEach((el) => {
      el.isSelected = false;
    });
  };

  // Управляем состоянием попапа
  const toggleExchangePopup = () => {
    isExchangePopupVisible.value = !isExchangePopupVisible.value;
  };

  // устанавливает предмет, который хотим вывести
  const setCurrentTradeItem = (item: IInventory) => {
    currentTradeItem.value = item;
  };

  const fetchInventory = async (params: TGetInventoryRequestDTO): Promise<TGetInventoryResponseDTO> => {
    const response = await GlobalUtils.Api.handleRequest(async () => {
      return await $api.inventory.getInventory(params);
    });

    return response ?? ({} as TGetInventoryResponseDTO);
  };

  const {
    data: inventoryData,
    pending: inventoryPending,
    refresh: refreshInventory,
  } = useLazyAsyncData<TGetInventoryResponseDTO>(
    'get-inventory',
    async () => {
      const params: TGetInventoryRequestDTO = {
        group: isAllInventory.value ? EGroupSortParams.ALL : EGroupSortParams.PROFILE,
        page: pagination.currentPage.value,
        perPage: pagination.currentPageSize.value,
        ...(Number(route.params.userId) && { userId: Number(route.params.userId) }),
      };

      const response = await fetchInventory(params);
      if (response.data.items) {
        totalActiveItems.value =
          params.group === EGroupSortParams.ACTIVE ? response.data.totalItem : totalActiveItems.value;
        total.value = response.data.totalItem;
        userInventory.value.push(...response.data.items);
        totalPrice.value = response.data.totalPrice;
      }

      return response;
    },
    {
      default: () => ({}) as TGetInventoryResponseDTO,
      immediate: false,
    },
  );

  watch(
    isUserLoaded,
    async (isLoaded) => {
      if (isLoaded) {
        await refreshInventory();
      }
    },
    { immediate: false },
  );

  watch(isAllInventory, async () => {
    userInventory.value = [];
    await refreshInventory();
  });

  // продать предмет
  const sellItem = async (id: number) => {
    try {
      await $api.inventory.sellManyInventory([id]);
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  };

  // продать все предметы
  const sellAllItems = async () => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        await $api.inventory.sellAllItems();

        const totalItemValue: number = user?.value?.finance?.totalAmountItems
          ? +user?.value?.finance?.totalAmountItems
          : 0;

        userStore.increaseBalanceOn(totalItemValue);
        userStore.decrementTotalAmountItems(totalItemValue);

        alertStore.show({
          title: AlertCodes.ALL_ITEMS_IN_SALE_QUEUE,
          type: 'success',
        });
      },
      (e) => {
        alertStore.showError({
          // @ts-ignore
          message: e,
          title: ErrorCodes.UNPREDICTED_EXCEPTION,
        });
      },
    );
  };

  // заменить предмет на другие предметы
  const tradeItem = (id: number) => {
    return $api.inventory.tradeItem(id);
  };

  // Запрос на вывод скина
  const sendItem = async (ids: number | number[], method?: string): Promise<TInventorySendItemResponseDTO> => {
    return (
      (await GlobalUtils.Api.handleRequest(async () => {
        const itemIds = Array.isArray(ids) ? ids : [ids];
        const response = await $api.inventory.sendItem(itemIds, method);

        if (response.data.status) {
          alertStore.show({
            type: 'success',
            title: AlertCodes.ITEM_IN_WITHDRAWAL_QUEUE,
          });
          userInventory.value = userInventory.value.map((item: IInventory) => {
            if (itemIds.includes(item.id)) {
              return {
                ...item,
                status: EInventoryItemStatus.PREPARING,
                timerData: response.data.untilSending,
              };
            }
            return item;
          });
        } else {
          alertStore.showError({
            title: ErrorCodes.UNPREDICTED_EXCEPTION,
            message: response.data.msg,
          });
        }

        return response || ({} as TInventorySendItemResponseDTO);
      })) || ({} as TInventorySendItemResponseDTO)
    );
  };

  const sellDrop = async (id: string): Promise<void> => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        await $api.inventory.sellDrop(id);
        alertStore.show({ title: AlertCodes.ITEM_IN_SALE_QUEUE, type: 'success' });
      },
      (e) => {
        alertStore.showError({
          message: e.msg,
          title: ErrorCodes.UNPREDICTED_EXCEPTION,
        });
      },
    );
  };

  // Замена предмета
  const replaceItem = async (params: IReplaceInventoryItemRequestDTO) => {
    await GlobalUtils.Api.handleRequest(async () => {
      const response = await $api.inventory.replaceItem(params);
      if (response.data.status) {
        pagination.currentPage.value = 1;
        userInventory.value = [];
        await refreshInventory();
      }
    });
  };

  return {
    ChangeItemListEntity,
    changeItemList,
    currentExchangeTradeItem,
    currentTradeItem,
    inventoryData,
    inventoryPending,
    isAllInventory,
    isExchangePopupVisible,
    isTakeItemAvailable,
    pagination,
    quantitySelectedItems,
    refreshInventory,
    removeAllFromSelected,
    selectedAllItem,
    selectedInventory,
    sellAllItems,
    sellDrop,
    sellItem,
    sendItem,
    setCurrentTradeItem,
    toggleExchangePopup,
    toggleSelectItemState,
    total,
    totalPrice,
    totalSumSelectedItems,
    tradeItem,
    tradeItems,
    userInventory,
    isCurrentUser,
    selectedInventoryIds,
    replaceItem,
    currentInventoryExchangePopupTime,
    totalActiveItems,
  };
});
